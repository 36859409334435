import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'

import Home from './components/Home'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import Services from './components/Services'
import CustomDesign from './components/CustomDesign'
import Gallery from './components/Gallery'

const App = () => {
    return (
        <Router basename="/">
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/custom-design" element={<CustomDesign />} />
                <Route path="/gallery" element={<Gallery />} />
            </Routes>
            <Footer />
        </Router>
    )
}

export default App
