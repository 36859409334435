import React from 'react'
import Coords from './Coords'

type PositionButtonsProps = {
    adjustX: (direction: number) => void
    adjustY: (direction: number) => void
    position?: Coords
}

const PositionButtons: React.FC<PositionButtonsProps> = ({
    adjustX,
    adjustY,
    position,
}) => {
    const handleMoveLeft = () => {
        adjustX(-1)
    }

    const handleMoveRight = () => {
        adjustX(1)
    }

    const handleMoveUp = () => {
        adjustY(-1)
    }

    const handleMoveDown = () => {
        adjustY(1)
    }

    return (<div >
        <div className="mb-2">
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={handleMoveLeft}
            >
                ←
            </button>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={handleMoveRight}
            >
                →
            </button>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={handleMoveUp}
            >
                ↑
            </button>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleMoveDown}
            >
                ↓
            </button>
            {position ? (<span className="ml-2">Position: x={position.x}, y={position.y}</span>) : null}
        </div>
    </div>
    )
}

export default PositionButtons
