import React from 'react'

type ScaleButtonsProps = {
    adjustScale: (direction: number) => void
    scale?: number
}

const ScaleButtons: React.FC<ScaleButtonsProps> = ({
    adjustScale,
    scale,
}) => {
    const handleIncreaseScale = () => {
        adjustScale(1)
    }

    const handleDecreaseScale = () => {
        adjustScale(-1)
    }

    return (
        <div>
            <div className="mb-2">
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={handleIncreaseScale}
                >
                    +
                </button>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleDecreaseScale}
                >
                    -
                </button>
                {scale ? (<span className="ml-2">{`Scale: ${scale.toFixed(2)}`}</span>)
                    : null}
            </div>
        </div>
    )
}

export default ScaleButtons
