import React, { useEffect, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'

interface ColorPickerProps {
    applyColor: (color: string) => void,
    initialColor?: string
    label?: string
}

const ColorPicker: React.FC<ColorPickerProps> = ({ applyColor, initialColor = '#000000', label = '' }) => {
    const [color, setColor] = useState<string>(initialColor)
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false)

    const handleColorChange = (color: ColorResult) => {
        setColor(color.hex)
    }

    const handleShowColorPicker = () => {
        setShowColorPicker(!showColorPicker)
    }

    const handleApplyColor = () => {
        applyColor(color)
        setShowColorPicker(false)
    }


    useEffect(() => {
        applyColor(color)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="my-2 flex items-center">
            <button onClick={handleShowColorPicker} className="mr-4">
                {`Change ${label} color`}
            </button>
            {showColorPicker && (<>
                <div className='mr-4'>
                    <ChromePicker color={color} onChange={handleColorChange} />
                </div>
                <button onClick={handleApplyColor} >
                    {`Apply ${label} color`}
                </button>
            </>
            )}
        </div>
    )
}

export default ColorPicker
