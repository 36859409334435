import React, { useState, useRef, useEffect } from 'react'
import Coords from './Coords'
import ScaleButtons from './ScaleButtons'
import PositionButtons from './PositionButtons'

type AddImageFormProps = {
    drawImage: (image: File, position: Coords, scale: number) => void
    restoreCanvas: () => void
    saveCanvas: () => void
}

const AddImageForm: React.FC<AddImageFormProps> = ({
    drawImage,
    restoreCanvas,
    saveCanvas,
}) => {
    const [showForm, setShowForm] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<File | null>(null)
    const [scale, setScale] = useState(1)
    const [position, setPosition] = useState<Coords>({ x: 0, y: 0 })
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleShowForm = () => {
        setShowForm(true)
        saveCanvas()
    }

    const handleCloseForm = () => {
        setShowForm(false)
    }

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            setSelectedImage(file)
            console.log('handled image upload', file)
        }
    }

    const handleImageDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const file = event.dataTransfer.files?.[0]
        if (file) {
            setSelectedImage(file)
            console.log('handled image drop', file)
        }
    }

    const handleImageDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    const handleAdjustScale = (direction: number) => {
        if (direction > 0) {
            setScale(scale + 0.05)
        } else {
            setScale(Math.max(0, scale - 0.05))
        }
    }

    const handleAdjustX = (direction: number) => {
        setPosition({
            ...position,
            x: position.x + direction * 5
        })
    }

    const handleAdjustY = (direction: number) => {
        setPosition({
            ...position,
            y: position.y + direction * 5
        })
    }

    const handleCancel = () => {
        handleCloseForm()
        restoreCanvas()
    }

    useEffect(() => {
        if (!selectedImage) {
            return
        }

        drawImage(selectedImage, position, scale)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position, scale, selectedImage])

    return showForm ? (
        <div>
            {!selectedImage ? (
                <div
                    className="border-dashed border-2 border-gray-300 p-4 mt-4"
                    onDrop={handleImageDrop}
                    onDragOver={handleImageDragOver}
                >
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleImageUpload}
                        style={{ display: 'none' }}
                    />
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => fileInputRef.current?.click()}
                    >
                        Upload from device
                    </button>
                    <p className="mt-2">or</p>
                    <p>Drag and drop an image here</p>
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                </div>
            ) : (
                <div className="mt-4">
                    <ScaleButtons adjustScale={handleAdjustScale} scale={scale} />
                    <PositionButtons adjustX={handleAdjustX} adjustY={handleAdjustY} position={position} />
                    <button
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                        onClick={handleCloseForm}
                    >
                        Save image
                    </button>
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    ) : (<button className="m-2" onClick={handleShowForm} >
        Add image
    </button>
    )
}

export default AddImageForm
