import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <nav className="bg-blue-800 py-4 px-6">
            <div className="container mx-auto flex items-center justify-between">
                <Link to="/" className="text-white font-bold text-lg">
                    Home
                </Link>
                <ul className="flex space-x-4">
                    <li>
                        <Link to="/services" className="text-white hover:text-gray-300">
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link to="/gallery" className="text-white hover:text-gray-300">
                            Gallery
                        </Link>
                    </li>
                    <li>
                        <Link to="/custom-design" className="text-white hover:text-gray-300">
                            Custom Design
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar
