import React, { useEffect, useState } from 'react'
import Coords from './Coords'
import ScaleButtons from './ScaleButtons'
import PositionButtons from './PositionButtons'

type AddTextFormProps = {
    drawText: (text: string, position: Coords, font: string) => void
    restoreCanvas: () => void
    saveCanvas: () => void
}

const AddTextForm: React.FC<AddTextFormProps> = ({
    drawText,
    restoreCanvas,
    saveCanvas,
}) => {
    const [showForm, setShowForm] = useState<boolean>(false)
    const [fontName, setFontName] = useState<string>('Verdana')
    const [fontSize, setFontSize] = useState(30)
    const [position, setPosition] = useState<Coords>({ x: 0, y: 70 })
    const [text, setText] = useState<string>('')

    const handleShowForm = () => {
        setShowForm(true)
        saveCanvas()
    }

    const handleCloseForm = () => {
        setShowForm(false)
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()

        setText(event.target.value)
    }

    const handleFontNameChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault()

        setFontName(event.target.value)
    }

    const handleAdjustFontSize = (direction: number) => {
        if (direction > 0) {
            setFontSize(fontSize + 1)
        } else {
            setFontSize(Math.max(1, fontSize - 1))
        }
    }

    const handleAdjustX = (direction: number) => {
        setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x + direction * 5
        }))
    }

    const handleAdjustY = (direction: number) => {
        setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y + direction * 5
        }))
    }

    const handleCancel = () => {
        handleCloseForm()
        restoreCanvas()
    }

    useEffect(() => {
        const font = `${fontSize}px ${fontName}`

        drawText(text, position, font)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fontName, fontSize, position, text])

    return showForm ? (
        <div>
            <h2>Add Text</h2>
            <div>
                <div>
                    <label htmlFor="text">Text:</label>
                    <textarea id="text" value={text} onChange={handleTextChange} />
                </div>
                <div>
                    <label htmlFor="fontSelect">Font:</label>
                    <select id="fontSelect" value={fontName} onChange={handleFontNameChange}>
                        <option value="Arial">Arial</option>
                        <option value="Comic Sans MS">Comic Sans MS</option>
                        <option value="Courier">Courier</option>
                        <option value="Courier New">Courier New</option>
                        <option value="Garamond">Garamond</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Helvetica">Helvetica</option>
                        <option value="Palatino">Palatino</option>
                        <option value="Times">Times</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Trebuchet MS">Trebuchet MS</option>
                        <option value="Verdana">Verdana</option>
                    </select>
                </div>
                <ScaleButtons adjustScale={handleAdjustFontSize} scale={fontSize} />
                <PositionButtons adjustX={handleAdjustX} adjustY={handleAdjustY} position={position} />

                <button className="mr-4" type="button" onClick={handleCloseForm}>Save text</button>
                <button className="mr-4" type="button" onClick={handleCancel}>Remove text</button>
            </div>
        </div>
    ) : (<button className="mr-2 my-2" onClick={handleShowForm} >
        Add text
    </button>
    )
}

export default AddTextForm
