import React from 'react'

const Services = () => {
    return (
        <div className="page services">
            <h1>Services</h1>
            <p>
                We offer a variety of custom mailbox painting services to match your unique style.
            </p>

            <div className="mb-6">
                <h3 className='bg-blue-800 p-2'>Single Color Mailbox Renewal</h3>
                <p className='bg-blue-500 p-2'>$25 per mailbox</p>
                <p className='px-12'>Our classic, single color, paint service. If your mailbox is showing signs of age, or you just want to change your color, this is the service for you.</p>
            </div>

            <div className="mb-6">
                <h3 className='bg-blue-800 p-2'>College Logos</h3>
                <p className='bg-blue-500 p-2'>$50 per box</p>
                <p className='px-12'>
                    Want to show your college football allegiance proudly? Get a Georgia G on your mailbox. Price includes a single color on the entire mailbox, plus the logo on one side.
                </p>
                <p className='px-12'>Have a split household? Get Auburn on one side, and Georgia on the other. ($50 for each side)</p>
                <p className='px-12'>Georgia will also do Alabama script A's for twice the price.</p>
            </div>

            <div className="mb-6">
                <h3 className='bg-blue-800 p-2'>Deluxe Mailbox Painting</h3>
                <p className='bg-blue-500 p-2'>$100 per box</p>
                <p className='px-12'>
                    Want something really special? Our Deluxe service includes working with you to design the perfect scene for your mailbox.
                </p>
            </div>

            <div className="mb-6">
                <h3 className='bg-blue-800 p-2'>Murals</h3>
                <p className='bg-blue-500 p-2'>Prices vary</p>
                <p className='px-12'>
                    Looking for something larger than a mailbox? Like the side of your shed, or wall in your house? Georgia can do it. Contact her for pricing.
                </p>
            </div>
        </div>
    )
}

export default Services