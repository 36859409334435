import React, { useState } from 'react'
import './Gallery.css'

const Gallery = () => {
    const [currentImage, setCurrentImage] = useState(0)

    const photos = [
        "/images/7265-bk-lt.png",
        "/images/7265-fr-rt.png",
        "/images/co-box.png",
        "/images/workshop.png",
    ]

    const nextPhoto = () => {
        setCurrentImage((currentImage + 1) % photos.length)
    }

    const prevPhoto = () => {
        setCurrentImage((currentImage + photos.length - 1) % photos.length)
    }

    return (
        <div className='page gallery'>
            <h1>Gallery</h1>

            <p>Georgia loves being able to fully immerse herself in each custom art project. Below is a gallery highlighting some of her recent favorite mailbox paintings.</p>


            <button className="prev-button" onClick={prevPhoto}>Prev</button>
            <button className="next-button" onClick={nextPhoto}>Next</button>

            <img src={photos[currentImage]} alt="" />
        </div>
    )
}





export default Gallery
