import React from 'react'
import './Home.css'

const Home = () => {
    return (
        <div className='page home'>
            <h1>Georgia's Custom Canvas</h1>

            <p>
                Georgia is a high school girl who recently moved from Colorado to LaGrange, Georgia.
                She loves playing volleyball and throwing the discus on her school track team.
            </p>

            <img src="/images/georgia.png" alt="Georgia" />

            <p>
                One of her favorite hobbies is creating custom artwork. She started a business painting
                custom designs on mailboxes, which is a quirky passion she developed after moving to
                Georgia and getting her first ever mailbox!
            </p>

            <p>
                Back in Colorado, the properties were too remote for mailboxes. Georgia loves her new
                home in Georgia and being able to express her creativity on these mailbox canvases.
            </p>
        </div>
    )
}

export default Home