import React, { FormEvent, useEffect, useRef, useState } from 'react'
import ColorPicker from './ColorPicker'
import AddTextForm from './AddTextForm'
import AddImageForm from './AddImageForm'
import Coords from './Coords'
import './CustomDesign.css'

const CustomDesign: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [prevCoords, setPrevCoords] = useState<Coords | null>(null)
    const [foregroundColor, setForegroundColor] = useState<string>('#ffffff')
    const [isDrawing, setIsDrawing] = useState<boolean>(false)
    const [imageData, setImageData] = useState<ImageData | null>(null)

    const updateCanvasSize = (width: number, height: number) => {
        const canvas = canvasRef.current

        if (!canvas || height === 0) {
            return
        }

        const canvasWidth = canvas.getBoundingClientRect().width
        const aspectRatio = width / height
        const canvasHeight = canvasWidth / aspectRatio

        canvas.width = canvasWidth
        canvas.height = canvasHeight
    }

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const heightInput = document.getElementById('height') as HTMLInputElement
        const widthInput = document.getElementById('width') as HTMLInputElement

        const height = parseFloat(heightInput.value)
        const width = parseFloat(widthInput.value)

        updateCanvasSize(width, height)
    }

    const handleRestoreCanvas = () => {
        const canvas = canvasRef.current
        const context = canvas?.getContext('2d')

        if (!canvas || !context || !imageData) {
            return
        }

        context.putImageData(imageData, 0, 0)
    }

    const handleSaveCanvas = () => {
        const canvas = canvasRef.current
        const context = canvas?.getContext('2d')

        if (!canvas || !context) {
            return
        }

        const imageData = context.getImageData(0, 0, canvas.width, canvas.height)

        setImageData(imageData)
    }

    const handleApplyBackgroundColor = (color: string) => {
        const canvas = canvasRef.current
        const context = canvas?.getContext('2d')

        if (!canvas || !context) {
            return
        }

        context.fillStyle = color
        context.fillRect(0, 0, canvas.width, canvas.height)
    }

    const handleApplyForegroundColor = (color: string) => {
        setForegroundColor(color)
    }

    const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
        setIsDrawing(true)
        const coords = getCanvasCoordinates(event)
        if (!coords) {
            return
        }

        setPrevCoords(coords)
    }

    const handleMouseUp = () => {
        setIsDrawing(false)
    }

    const getCanvasCoordinates = (event: React.MouseEvent<HTMLCanvasElement>): undefined | Coords => {
        const rect = canvasRef.current?.getBoundingClientRect()

        if (!rect) {
            return undefined
        }

        return {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
        }
    }

    const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (!isDrawing) {
            return
        }

        const coords = getCanvasCoordinates(event)
        const context = canvasRef.current?.getContext("2d")

        if (!context || !coords) {
            return
        }

        context.beginPath()
        context.strokeStyle = foregroundColor
        context.lineWidth = 2
        context.lineCap = 'round'
        context.moveTo(prevCoords?.x || coords.x, prevCoords?.y || coords.y)
        context.lineTo(coords.x, coords.y)
        context.stroke()
        setPrevCoords(coords)
    }

    const lineHeightFromFont = (font: string): number => {
        const pattern = /(\d+)px/
        const matches = font.match(pattern)

        if (!matches || matches.length < 2) {
            return 0
        }

        return parseInt(matches[1])

    }

    const handleAddTextToCanvas = (text: string, position: Coords, font: string) => {
        const canvas = canvasRef.current
        const context = canvas?.getContext('2d')

        if (!canvas || !context) {
            return
        }

        if (imageData) {
            context.putImageData(imageData, 0, 0)
        }

        const x = position.x
        const lineHeight = lineHeightFromFont(font)
        let y = position.y
        context.font = font
        context.fillStyle = foregroundColor
        text.split('\n').forEach((line) => {
            context.fillText(line, x, y)
            y += lineHeight
        })
    }

    const handleAddImageToCanvas = (image: File, position: Coords, scale: number) => {
        const canvas = canvasRef.current
        const context = canvas?.getContext('2d')

        if (!canvas || !context) {
            return
        }

        if (imageData) {
            context.putImageData(imageData, 0, 0)
        }

        const imageObj = new Image()
        imageObj.onload = () => {
            const { width, height } = imageObj

            const scaleWidth = scale * width
            const scaleHeight = scale * height

            context.drawImage(imageObj, position.x, position.y, scaleWidth, scaleHeight)
        }

        const reader = new FileReader()
        reader.onload = (e) => {
            if (e.target?.result) {
                imageObj.src = e.target.result.toString()
            }
        }

        reader.readAsDataURL(image)
    }

    useEffect(() => {
        updateCanvasSize(3, 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef?.current])

    return (
        <div className="page customDesign">
            <h1>Custom Design</h1>

            <div className="message">
                <p>Work In Progress - Design Only</p>
                <p>
                    Use this page to experiment with designs. Email screenshots for now.
                </p>
                <p>Live orders coming soon!</p>
            </div>

            <form onSubmit={handleFormSubmit} className="text-white">
                <div className="mb-4">
                    <label htmlFor="height" className="block">
                        Approximate mailbox height (inches):
                    </label>
                    <input type="number" id="height" name="height" />
                </div>
                <div className="mb-4">
                    <label htmlFor="width" className="block">
                        Approximate mailbox width (inches):
                    </label>
                    <input type="number" id="width" name="width" />
                </div>
                <button type="submit">
                    Change mailbox shape
                </button>
            </form>
            <h2 className='mt-12'>Customize your mailbox design</h2>
            <canvas
                className="mt-8 w-full border-2 border-white"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                ref={canvasRef}
            >
            </canvas>
            <ColorPicker applyColor={handleApplyBackgroundColor} label='background' />
            <ColorPicker applyColor={handleApplyForegroundColor} initialColor='#ffffff' label='foreground' />
            <AddTextForm drawText={handleAddTextToCanvas} restoreCanvas={handleRestoreCanvas} saveCanvas={handleSaveCanvas} />
            <AddImageForm drawImage={handleAddImageToCanvas} restoreCanvas={handleRestoreCanvas} saveCanvas={handleSaveCanvas} />
        </div>
    )
}

export default CustomDesign
