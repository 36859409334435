import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div>
                    <h3>To request a service, or for more info, contact Georgia.</h3>
                    <p>
                        <strong>Phone:&nbsp;</strong>
                        <a href="tel:9705316821">970.531.6821</a>
                    </p>
                    <p>
                        <strong>Email:&nbsp;</strong>
                        <a href="mailto:coloradoogirll@gmail.com">coloradoogirll@gmail.com</a>
                    </p>
                </div>
            </div>
            <div className="copyright">
                &copy; 2023 Georgia's Curbside Canvas. All rights reserved.
            </div>
        </footer>
    )
}

export default Footer